/**
 * Created by WorkAxis www.workaxis.in
 * Developer: Padma Dev.E
 * Developer Contact: padmadev.e@workaxis.in
 * Admin: shyam@workaxis.in
 * Date: 3/13/2022
 * Time: 9:52 AM
 * Project: w_crm
 */
import React, {lazy} from "react";
import {Route, Routes} from "react-router-dom";
import {getUUID} from "../utils/util";
import PublicLayout from "./PublicLayout";
import PrivateLayout from "./PrivateLayout";
import {
    URL_ANALYTICS,
    URL_APPOINTMENT_RESCHEDULE,
    URL_BOOKING,
    URL_CALENDAR,
    URL_CHANGE_PASSWORD,
    URL_CLIENT_DETAILS,
    URL_CLIENTS,
    URL_CONTACT_T1,
    URL_CONTACT_T2,
    URL_CONTACT_T3,
    URL_CONTACT_WIDGET,
    URL_CREATE_E_FORM,
    URL_DOCUMENTS,
    URL_E_FORM,
    URL_EDIT_E_FORM,
    URL_FILL_E_FORM,
    URL_FORGOT_PASSWORD,
    URL_HOME,
    URL_INTEGRATION,
    URL_LEAD_DETAILS,
    URL_Leads,
    URL_LOGIN,
    URL_New_WHATSAPP_TEMPLATE,
    URL_NOTICE,
    URL_ORGANIZATION,
    URL_PAGE_NOT_FOUND,
    URL_PRICING,
    URL_PROFILE,
    URL_REGISTER,
    URL_RESET_PASSWORD,
    URL_RESPONSE_E_FORM,
    URL_SUBSCRIPTION,
    URL_TASK,
    URL_VERIFY_EMAIL,
    URL_VIEW_E_FORM,
    URL_Whatsapp_Enquiry,
    URL_WHATSAPP_INTEGRATION,
} from "./url";
import {useDispatch, useSelector} from "react-redux";
import CalendarDays from "../icon/CalendarDays";
import UserGroup from "../icon/UserGroup";
import Users from "../icon/Users";
import BuildingOffice from "../icon/BuildingOffice";
import Document from "../icon/Document";
import HomeIcon from "../icon/HomeIcon";
import SharedLayout from "./SharedLayout";
import ClientLayout from "./ClientLayout";
import TaskIcon from "../icon/TaskIcon";
import EfromIcon from "../icon/EfromIcon";
import ArrowTrendingUpIcon from "../icon/ArrowTrendingUpIcon";
import {memberRole, staffRole} from "../utils/constants";
import {profileState} from "../redux/profile/profileReducer";
import WhatsappRawIcon from "../icon/WhatsappRawIcon";
import ChatIcon from "../icon/ChatIcon";

// const Dashboard = lazy(() => import("../pages/Dashboard/Dashboard"));
const Dashboard = lazy(() => import("../pages/Dashboard/DashboardPage"));
const ChangePassword = lazy(() =>
    import("../pages/Credentials/ChangePassword")
);
const Profile = lazy(() => import("../pages/Profile/ProfilePage"));
const Subscription = lazy(() =>
    import("../pages/Subscription/SubscriptionPage")
);
const Calendar = lazy(() => import("../pages/BigCalendar/CalendarPage"));
// const Calendar = lazy(() => import("../pages/CalendarPage/CalendarPageFix"));
const Pricing = lazy(() => import("../pages/Pricingpage/PricingPage"));
const Clients = lazy(() => import("../pages/Clients/ClientsPage"));
const Leads = lazy(() => import("../pages/Lead/Lead"));
const ContactForm = lazy(
    () => import("../pages/ContactAppointmentWidget/ContactAppointmentWidget")
    // import("../pages/ContactAppointmentWidget/FixAppointmentWidget")
);
const ClientsDetails = lazy(() => import("../pages/Clients/ClientDetailsPage"));
const LeadDetails = lazy(() => import("../pages/Lead/LeadDetailPage"));
const LeadReschedule = lazy(() =>
    import("../component/Appointment/Reschedule")
);
const Organization = lazy(() => import("../pages/Organization/Organization"));
const Integration = lazy(() => import("../pages/Integration/IntegrationPage"));
const WhatsappIntegration = lazy(() => import("../pages/WhatsappIntegration/WhatsappIntegration"));
const DocumentsPage = lazy(() => import("../pages/Documents/DocumentsPage"));

const EFormList = lazy(() => import("../pages/E-form/EFormPage"));

const BuildEForm = lazy(() => import("../pages/E-form/EFormBuilder"));
const GenerateEForm = lazy(() => import("../pages/E-form/EFormResponse"));
const ViewEForm = lazy(() => import("../pages/E-form/EFormPreviewNew"));
const LeadEnquiry = lazy(() => import("../pages/Enquiry/Enquiry"));
const CreateNewWhatsappTemplate = lazy(() => import("../pages/WhatsappTemplates/CreateNewWhatsappTemplate"));

const Login = lazy(() => import("../pages/Credentials/Login"));
const PageNotFound = lazy(() => import("../pages/PageNotFound/PageNotFound"));
const Register = lazy(() => import("../pages/Credentials/Register"));
const ForgotPassword = lazy(() =>
    import("../pages/Credentials/ForgotPassword")
);
const Notice = lazy(() =>
    import("../pages/Common/NotificationPage/NoticePage")
);
const SetNewPassword = lazy(() =>
    import("../pages/Credentials/SetNewPassword")
);

const VerifyEmail = lazy(() =>
    import("../pages/Credentials/VerifyEmail")
);

const CustomFields = lazy(() => import("../pages/CustomFields/CustomFields"));
const TaskPage = lazy(() => import("../pages/Task&Notes/AllTaskListPage"));
const DirectAppointment = lazy(() =>
    import("../pages/ContactAppointmentWidget/DirectAppointmentPage")
);
const Analytics = lazy(() =>
    import("../pages/Analytics/AnalyticsPage")
);
const Testing = lazy(() =>
    import("../pages/Testing/TestingPage")
);
// const Dashboard = () => import('../pages/Dashboard/Dashboard');

// import Dashboard from "../pages/Dashboard/Dashboard";
//
// const ChangePassword = import('../pages/Credentials/ChangePassword') as ChangePassword;
// const Profile = import('../pages/Profile/ProfilePage') as Profile;
// const Subscription = import('../pages/Subscription/SubscriptionPage') as Subscription;
// const Pricing = import('../pages/Pricingpage/PricingPage') as Pricing;
// const Clients = import('../pages/Clients/ClientsPage') as Clients;
// const ClientsDetails = import('../pages/Clients/ClientDetailsPage') as ClientsDetails;
// const Organization = import('../pages/Organization/Organization') as Organization;
// const EFormPage = import('../pages/E-form/EFormPage') as EFormPage;
// const BuildEForm = import('../pages/E-form/EFormBuilder') as BuildEForm;
// const GenerateEForm = import('../pages/E-form/EFormResponse') as GenerateEForm;
// const ViewEForm = import('../pages/E-form/EFormPreviewNew') as ViewEForm;
// const Login = import('../pages/Credentials/Login') as Login;
// const Register = import('../pages/Credentials/Register') as Register;
// const ForgotPassword = import('../pages/Credentials/ForgotPassword') as ForgotPassword;
// const Notice = import('../pages/Common/NotificationPage/NoticePage') as Notice;
// const SetNewPassword = import('../pages/Credentials/SetNewPassword') as SetNewPassword;

export const routes = {
    private: [
        {
            path: URL_HOME,
            showOnNav: true,
            showOnMobile: true,
            // icon: <HomeIcon className={"w-1-5 h-1-5"} strokeWidth={1.5} />,
            icon: HomeIcon,
            name: "Home",
            component: Dashboard,
            notAllowed: [],
            configuration: {
                padding: "L",
            },
        },
        {
            path: URL_Leads,
            showOnNav: true,
            showOnMobile: true,
            icon: UserGroup,
            notAllowed: [],
            name: "Leads",
            component: Leads,
        },
        {
            path: URL_CLIENTS,
            showOnNav: true,
            showOnMobile: true,
            icon: Users,
            name: "Contacts",
            component: Clients,
            notAllowed: []
        },
        {
            path: URL_CALENDAR,
            showOnNav: true,
            showOnMobile: true,
            icon: CalendarDays,
            notAllowed: [],
            name: "Calendar",
            component: Calendar,
        },
        {
            path: URL_E_FORM,
            showOnNav: true,
            showOnMobile: false,
            icon: EfromIcon,
            notAllowed: [],
            name: "E Forms",
            component: EFormList,
        },
        {
            path: URL_ORGANIZATION,
            showOnNav: false,
            showOnMobile: false,
            icon: BuildingOffice,
            notAllowed: [memberRole, staffRole],
            name: "Organization",
            component: Organization,
        },
        {
            path: URL_INTEGRATION,
            showOnNav: false,
            showOnMobile: false,
            icon: BuildingOffice,
            notAllowed: [],
            name: "Integration",
            component: Integration,
        },
        {
            path: URL_WHATSAPP_INTEGRATION,
            showOnNav: false,
            showOnMobile: false,
            icon: WhatsappRawIcon,
            notAllowed: [],
            name: "Whatsapp",
            component: WhatsappIntegration,
        },
        {
            path: URL_DOCUMENTS,
            showOnNav: true,
            showOnMobile: false,
            icon: Document,
            notAllowed: [],
            name: "Documents",
            component: DocumentsPage,
        },
        {
            path: URL_TASK,
            showOnNav: true,
            showOnMobile: false,
            icon: TaskIcon,
            notAllowed: [],
            name: "Task",
            component: TaskPage,
        },
        {
            path: URL_Whatsapp_Enquiry,
            showOnNav: true,
            showOnMobile: true,
            icon: ChatIcon,
            notAllowed: [],
            name: "Enquiry",
            component: LeadEnquiry,
        },
        {
            path: URL_ANALYTICS,
            showOnNav: true,
            showOnMobile: false,
            icon: ArrowTrendingUpIcon,
            notAllowed: [memberRole, staffRole],
            name: "Analytics",
            component: Analytics,
        },
        {
            path: URL_New_WHATSAPP_TEMPLATE,
            showOnNav: false,
            showOnMobile: false,
            notAllowed: [],
            name: "Whatsapp template",
            component: CreateNewWhatsappTemplate,
        },
        {
            path: URL_SUBSCRIPTION,
            showOnNav: false,
            showOnMobile: false,
            name: "Subscription",
            notAllowed: [staffRole, memberRole],
            component: Subscription,
        },
        {
            path: URL_PRICING,
            showOnNav: false,
            showOnMobile: false,
            name: "Pricing",
            notAllowed: [],
            component: Pricing,
        },
        {
            path: URL_CHANGE_PASSWORD,
            showOnNav: false,
            showOnMobile: false,
            notAllowed: [],
            name: "Change Password",
            component: <ChangePassword/>,
        },
        {
            path: URL_PROFILE,
            showOnNav: false,
            showOnMobile: false,
            notAllowed: [],
            name: "Profile",
            component: Profile,
        }, //E Form
        {
            path: URL_CREATE_E_FORM,
            showOnNav: false,
            showOnMobile: false,
            notAllowed: [],
            name: "Create E-Form",
            component: BuildEForm,
        },
        {
            path: URL_VIEW_E_FORM + "/:id",
            showOnNav: false,
            showOnMobile: false,
            notAllowed: [],
            name: "View E-Form",
            component: ViewEForm,
        },
        {
            path: URL_EDIT_E_FORM + "/:id",
            showOnNav: false,
            showOnMobile: false,
            notAllowed: [],
            name: "Edit",
            component: BuildEForm,
        },
        {
            path: URL_RESPONSE_E_FORM,
            showOnNav: false,
            showOnMobile: false,
            notAllowed: [],
            name: "View E-Form Response",
            component: GenerateEForm,
        },
        {
            path: URL_CLIENT_DETAILS,
            showOnNav: false,
            showOnMobile: false,
            notAllowed: [],
            name: "Details",
            component: ClientsDetails,
        },
        {
            path: URL_LEAD_DETAILS,
            showOnNav: false,
            showOnMobile: false,
            notAllowed: [],
            name: "Details",
            component: LeadDetails,
        },
        {
            path: URL_CONTACT_WIDGET,
            showOnNav: false,
            showOnMobile: false,
            notAllowed: [],
            name: "Custom Fields",
            component: CustomFields,
        },
        // {
        //     path: URL_DEV_TESTING,
        //     showOnNav: false,
        //     showOnMobile: false,
        //     notAllowed: [],
        //     name: "Testing",
        //     component: Testing,
        // },
    ],
    public: [
        {path: URL_LOGIN, component: Login},
        {
            path: URL_FORGOT_PASSWORD,
            component: ForgotPassword,
        },
        {
            path: URL_REGISTER,
            component: Register,
        },
        {
            path: URL_RESET_PASSWORD,
            component: SetNewPassword,
        }, {
            path: URL_VERIFY_EMAIL,
            component: VerifyEmail,
        },
        {
            path: URL_NOTICE,
            component: Notice,
        },
    ],
    shared: [
        {path: URL_PAGE_NOT_FOUND, component: PageNotFound},
        {
            path: "*",
            component: PageNotFound,
        },
    ],
    client: [
        {path: URL_CONTACT_T1, component: ContactForm, regex: new RegExp(URL_CONTACT_T1.replace(":level1", "[^/]+"))},
        {
            path: URL_CONTACT_T2,
            component: ContactForm,
            regex: new RegExp(URL_CONTACT_T2.replace(":level1", "[^/]+").replace(":level2", "[^/]+")),
        },
        {
            path: URL_CONTACT_T3,
            component: ContactForm,
            regex: new RegExp(URL_CONTACT_T3.replace(":level1", "[^/]+").replace(":level2", "[^/]+").replace(":level3", "[^/]+"))
        },
        // {path: URL_CONTACT_WILD, component: ContactForm},
        {path: URL_BOOKING, component: DirectAppointment, regex: new RegExp(URL_BOOKING.replace(":level1", "[^/]+"))},
        {
            path: URL_FILL_E_FORM,
            showOnNav: false,
            name: "View E-Form",
            component: GenerateEForm,
        },
        {
            path: URL_APPOINTMENT_RESCHEDULE,
            showOnNav: false,
            showOnMobile: false,
            notAllowed: [],
            name: "Lead Reschedule",
            component: LeadReschedule,
        },
    ],
};

export default function UrlRoutes() {
    let dispatch = useDispatch();
    const {profile} = useSelector(profileState);
    return (
        <Routes>
            {routes.public.map((route, key) => {
                const {component, path} = route;
                return (
                    <Route
                        key={getUUID()}
                        path={path}
                        element={<PublicLayout component={component}/>}
                    />
                );
            })}

            {routes.private.map((route, key) => {
                const {component, path, configuration, notAllowed} = route;
                if (!notAllowed.includes(profile.data?.userorgrolemapping?.role?.name)) {
                    return (
                        <Route
                            key={getUUID()}
                            path={path}
                            element={
                                <PrivateLayout
                                    component={component}
                                    configuration={configuration}
                                />
                            }
                        />
                    );
                }
            })}

            {routes.shared.map((route, key) => {
                const {component, path} = route;
                return (
                    <Route
                        key={getUUID()}
                        path={path}
                        element={<SharedLayout component={component}/>}
                    />
                );
            })}

            {routes.client.map((route, key) => {
                const {component, path} = route;
                return (
                    <Route
                        key={getUUID()}
                        path={path}
                        element={<ClientLayout component={component}/>}
                    />
                );
            })}
        </Routes>
    );
}
