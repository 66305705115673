import React from "react";

export default function IntegrationIcon({
                                            strokeWidth = 3,
                                            className = "w-1",
                                            stroke = "currentColor",
                                            fill = "none",
                                        }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            className={className}
            fill={fill}
            viewBox="0 0 24 24"
            stroke={stroke}
        >

            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={strokeWidth}
                  d="M13.19 8.688a4.5 4.5 0 0 1 1.242 7.244l-4.5 4.5a4.5 4.5 0 0 1-6.364-6.364l1.757-1.757m13.35-.622 1.757-1.757a4.5 4.5 0 0 0-6.364-6.364l-4.5 4.5a4.5 4.5 0 0 0 1.242 7.244"/>


        </svg>
    );
}
