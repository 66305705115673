export const endPoints = {
    auth: {
        login: "user/login/",
        googleLogin: "user/google/login/",
        logout: "user/logout/",
        refresh_token: "user/refresh-token/",
        profile: "user/user-summary/",
        fcm_token: "user/users/token/",
        register: "user/users/",
        forgot_password: "user/password/reset/",
        verify_forgot_password: "user/password/reset/confirm/",
        change_password: "user/change-password/",
        change_email: "user/users/change-email/",
        verify_email: "/user/registration/verify-email/",
        resend_verify_email: "/user/registration/resend-email/",
        get_id_details: "https://geolocation-db.com/json",
        user_preferences: "user/user-preferences/",
        transfer: "user/user-org-role-mapping/transfer/",
        transfer_list: "user/user-org-role-mapping/transfer-list/",
        unmapUser: "/user/user-org-role-mapping/unmap/",
        deleteUser: "/user/user-org-role-mapping/user-delete/", // public only
        deleteAccount: "/user/user-org-role-mapping/account-delete/", // enterprise only
    },
    subscription: {
        calculate_subscription_cost: "subscriptions/user-owned-subscription-mapping/calculate-fee/",
        fetch_types: "subscriptions/subscriptions/",
        fetch_plans: "subscriptions/subscription-plans/",
        proceed_to_pay: "subscriptions/user-owned-subscription-mapping/",
        payment: "subscriptions/payment/",
    },
    e_form: {
        callType: {
            all: "",
            client: "?form_mapping__client=",
        },
        create: "e-form/",
        clientInvite: "/e-form/invite/",
        fillForm: "/e-form/invite/",
        response: "/e-form/response/",
        generate_otp: "/e-form/response/generate_otp/",
        event: "/e-form/event/",
        access: "/e-form/access/",
        access_list: "/e-form/access/assigned/",
        unAssigned: "/user/users/",
        revoke: "/e-form/access/revoke/",
        download: "/e-form/response/generate/",
        mail: "/e-form/response/email/",
    },
    client: {
        list: "clients/",
        export: "clients/export/",
        detail: "clients/{{id}}/",
        lead: "leads/{{id}}/",
        tags: "clients/client_tags/",
    },
    document: {
        upload: "documents/",
        download: "documents/download",
        email: "documents/email/",
        callType: {
            client: "?client=",
            form: "?form=",
        },
    },
    communication: {
        getEmailConversation: "/email/",
        download: "email/attachment",
        thread: "email/thread",
    },
    organization: {
        get: "user/organizations/",
        users: "user/users/my_org_users/",
        invites: "subscriptions/invites/",
        myInvites: "subscriptions/invites/",
        invite: "subscriptions/user-owned-subscription-mapping/send-invite/",
        accept: "/subscriptions/user-subscription-mapping/accept-invite/",
        customField: "/custom-fields-for-lead/",
        customLead: "/custom-status-for-lead/",
        customLeadBulk: "/custom-status-for-lead/bulk-update/",
        decline: "/subscriptions/user-subscription-mapping/decline-invite/",
        deleteOrg: "/user/user-org-role-mapping/delete-organization/",
    },
    group: {
        get: "/user/groups/",
        selected: "/user/user-group-mapping/",
        userMapping: "/user/user-group-mapping/",
    },
    lead: {
        get: "/leads/",
        export: "/leads/export/",
        growth: "/leads/growth/",
        lead_chart: "/leads/lead_chart/",
        appointment: "/appointments/slots/",
        calendarAppointment: "/appointments/",
        calendarTask: "/task/",
        leadStat: "/leads/leads-summary/",
        leadUsers: "/user/users/",
        leadAppointments: "/leads/:id/appointments-for-lead/",
    },
    task: {
        list: "/task/",
        bulkDelete: "/task/bulk-delete/",
        bulkCompletion: "/task/bulk-update/",
    },
    analytics: {
        conversion_ratio: "/leads-source-analytics/lead-conversion/",
        device_traffic: "/leads-source-analytics/device-traffic/",
        lead_source: "/leads-source-analytics/lead-source/",
        referral: "/leads-source-analytics/",
    },
    announcement: {
        base: "/announcement/"
    },
    integration: {
        listMessage: "/integration/whatsapp/",
        listEnquiryMessage: "/integration/whatsapp-enquiry/",
        bulkWhatsapp: "/integration/whatsapp/bulk/",
        bulkWhatsappStatus: "/integration/whatsapp/bulk_status/",
        templates: "/integration/whatsapp/templates/",
        deleteTemplates: "/integration/whatsapp/templates/{{id}}/",
        search: "/integration/whatsapp/search/",
        createConfig: "/integration/",
        whatsappPhoneNumberConfirmation: "/integration/confirmation/",
        whatsappAnalytics: "/integration/analytics/",
        unidentifiedSender: "/unidentified-enquiry/",
        deleteUnidentifiedSender: "/unidentified-enquiry/{{id}}/",
        isWhatsappConfig: "/integration/whatsapp/config/",
        email: {
            config: "/integration/email/config/"
        }
    }
};
