/**
 * Created by WorkAxis www.workaxis.in
 * Developer: Padma Dev.E
 * Developer Contact: padmadev.e@workaxis.in
 * Admin: shyam@workaxis.in
 * Date: 3/31/2022
 * Time: 12:52 PM
 * Project: w_crm
 */
import React from "react";
import "./style.css";
import {Tooltip as ReactTooltip} from 'react-tooltip'

export default function Tooltip({className, title = null, toolTip = null, children, ...props}) {
    // const positionRef = useRef(null);
    // const tooltipRef = useRef(null);
    //
    // let Css = `tooltip-parent ${className}`;
    //
    // const [floaterPosition, setFloaterPosition] = useState({
    //     top: 0,
    //     left: 0,
    // });
    // const updatePosition = () => {
    //     const parent = positionRef.current?.getBoundingClientRect();
    //     const child = tooltipRef.current?.getBoundingClientRect();
    //
    //     if (parent && child) {
    //         const topNew = parent.top - child.height - 5;
    //         const leftNew = parent.left + parent.width / 2 - child.width / 2;
    //         const newPosition = {
    //             top: topNew,
    //             left: leftNew,
    //         };
    //         if (!isEqual(newPosition, floaterPosition)) {
    //             setFloaterPosition(newPosition);
    //         }
    //     }
    // };
    //
    // useEffect(() => {
    //     updatePosition();
    //     const handleMouseEnter = () => updatePosition();
    //     const handleMouseLeave = () => setFloaterPosition({top: 0, left: 0});
    //
    //     positionRef.current.addEventListener("mouseenter", handleMouseEnter);
    //     positionRef.current.addEventListener("mouseleave", handleMouseLeave);
    //
    //     return () => {
    //         positionRef.current?.removeEventListener("mouseenter", handleMouseEnter);
    //         positionRef.current?.removeEventListener("mouseleave", handleMouseLeave);
    //     };
    // }, []);

    return <>
        <ReactTooltip id="my-tooltip" {...props} place={"top"} positionStrategy={"fixed"} style={{maxWidth: 300,zIndex:100}}/>

        <div data-tooltip-id="my-tooltip" data-tooltip-content={toolTip}>
            {children}
        </div>

    </>

    // return (
    //     <div className={Css} ref={positionRef}>
    //         {children}
    //         {(toolTip || title) && (
    //             <div
    //                 className="tooltip-child"
    //                 style={{
    //                     top: floaterPosition.top,
    //                     left: floaterPosition.left,
    //                     opacity: floaterPosition.top !== 0 ? 1 : 0,
    //                 }}
    //                 ref={tooltipRef}
    //             >
    //                 {title &&
    //                     <p className={"text-default text-bold"}>{title}</p>
    //                 }
    //                 {toolTip &&
    //                     <p className={"text-default"}>{toolTip}</p>
    //                 }
    //             </div>
    //         )}
    //     </div>
    // );
}
