/**
 * Created by WorkAxis www.workaxis.in
 * Developer: Padma Dev.E
 * Developer Contact: padmadev.e@workaxis.in
 * Admin: shyam@workaxis.in
 * Date: 07/11/22
 * Time: 10:22 AM
 * Project: W-CRM
 */
import React, {useEffect, useRef, useState} from "react";
import "./style.css";
import Button from "../component/Button/Button";
import useObserver from "../utils/userObserver";
import Pagination from "../component/Pagination/Pagination";
import Filter from "../component/Filter/Filter";
// import InternalLoading from "../component/LoadingSpinner/InternalLoading";
import Spinner from "../component/Spinner/Spinner";
import {FilterType} from "../utils/constants";

export default function PlayGround({
                                       isLoading = false,
                                       isScroll = true,
                                       overrideScroll = false,
                                       isControl = false,
                                       isFilter = false,
                                       isPagination = false,
                                       controls = [],
                                       onSearchChange = () => {
                                       },
                                       onDateChange = () => {
                                       },
                                       onOptionChange = () => {
                                       },
                                       filterOptions = [],
                                       dateFilterDefault = {start: null, end: null},
                                       optionFilterDefault = null,
                                       isSearch = false,
                                       isOptions = false,
                                       isDate = false,
                                       totalCount = 0,
                                       currentPage = 0,
                                       itemPerPage = 10,
                                       onPageChange = () => {
                                       },
                                       placeholder,
                                       customFilter,
                                       extraActions = [],
                                       showExtraActions = true,
                                       children,
                                   }) {
    const [matches, ref] = useObserver();

    const bodyRef = useRef(null);

    const [scrollable, setScrollable] = useState(isScroll);

    useEffect(() => {
        if (overrideScroll) {
            setScrollable(true);
        } else {
            if (isScroll) {
                if (!matches.desktop) {
                    setScrollable(false);
                } else {
                    setScrollable(true);
                }
            }
        }
    }, [matches, overrideScroll, isScroll]);

    return (
        <>
            <div className={"playgroundParentContainer"}>
                <div className={"playgroundHeadFootHolder"}>
                    <div className={"playgroundHeader"} ref={ref}>
                        {isFilter && (
                            <div className={"playgroundQueryHolder"}>
                                <Filter
                                    isDateFilter={isDate}
                                    isOptionFilter={isOptions}
                                    isSearchFilter={isSearch}
                                    customFilter={customFilter}
                                    onOptionChange={onOptionChange}
                                    onDateChange={onDateChange}
                                    onSearchChange={onSearchChange}
                                    extraActions={extraActions}
                                    showExtraActions={showExtraActions}
                                    options={filterOptions.map((i) => ({id: i, value: i}))}
                                    dateFilterDefault={dateFilterDefault}
                                    optionFilterDefault={optionFilterDefault}
                                    placeholder={{
                                        [FilterType.dateRange]: "Filter by created on",
                                        [FilterType.option]: "Filter by status type",
                                        [FilterType.search]: "Search here...",
                                        ...placeholder,
                                    }}
                                />
                            </div>
                        )}
                        {isControl && (
                            <div className={"playgroundControlHolder"}>
                                {controls.map((control) => {
                                    return (
                                        <div>
                                            <Button
                                                key={`pl-${control.title}`}
                                                onClick={control.action}
                                                disabled={control.disabled}
                                            >
                                                {control.title}
                                            </Button>
                                        </div>
                                    );
                                })}
                            </div>
                        )}
                    </div>
                </div>
                <div className={`playgroundBody ${scrollable ? 'scrollable' : ''}`}>
                    <Spinner isSpinning={isLoading}>
                        <>{children}</>
                    </Spinner>
                </div>
                <div className={"playgroundHeadFootHolder"}>
                    {isPagination && totalCount > 10 && (
                        <div className={"playgroundFooter"}>
                            <Pagination
                                onPageChange={(val) => {
                                    onPageChange(val);
                                }}
                                totalCount={totalCount}
                                currentPage={currentPage}
                                pageSize={itemPerPage}
                                siblingCount={matches.mobile ? 0 : 1}
                            />
                        </div>
                    )}
                </div>
            </div>
        </>
    );
}
