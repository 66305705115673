/**
 * Created by WorkAxis www.workaxis.in
 * Developer: Padma Dev.E
 * Developer Contact: padmadev.e@workaxis.in
 * Admin: shyam@workaxis.in
 * Date: 17/07/22
 * Time: 6:43 PM
 * Project: W-CRM
 */
import {createSlice, current} from "@reduxjs/toolkit";
import apiService from "../../api/apiService";
import {formatAxiosErrorMessage, qargsToQstring} from "../../utils/common";
import {endPoints} from "../../api/endpoints";
import {replaceArrayObjectItem} from "../../utils/util";

const CommunicationInitialData = {
    email: {
        isLoading: false,
        data: null,
        error: null,
    },
    sendEmail: {
        isLoading: false,
        data: null,
        error: null,
    },
    fileRequest: {
        isLoading: false,
        data: null,
        error: null,
    },
    thread: {
        isLoading: false,
        data: null,
        error: null,
    },
    update: {
        isLoading: false,
        data: null,
        error: null,
    },
};

export const CommunicationSlice = createSlice({
    name: "communication",
    initialState: {
        ...CommunicationInitialData,
    },
    reducers: {
        emailCommunicationRequesting: (state, action) => {
            state.email.isLoading = true;
            state.email.isCreated = false;
        },
        emailCommunicationSuccess: (state, action) => {
            const {email} = current(state);
            const newData = action.payload.data;
            if (email?.data?.results) {
                newData.results = [...email.data.results, ...newData.results];
            }
            state.email.isLoading = false;
            state.email.error = null;
            state.email.data = newData;
        },
        emailCommunicationRefreshSuccess: (state, action) => {
            state.email.isLoading = false;
            state.email.error = null;
            state.email.data = action.payload.data;
        },
        emailCommunicationError: (state, action) => {
            state.email.isLoading = false;
            state.email.isCreated = false;
            state.email.error = action.payload;
        },
        emailCommunicationReset: (state, action) => {
            state.email = CommunicationInitialData.email;
        },

        sendEmailRequesting: (state, action) => {
            state.sendEmail.isLoading = true;
            state.sendEmail.error = null;
            state.sendEmail.data = null;
        },
        sendEmailSuccess: (state, action) => {
            state.sendEmail.isLoading = false;
            state.sendEmail.error = null;
            state.sendEmail.data = action.payload.data;
        },
        sendEmailError: (state, action) => {
            state.sendEmail.isLoading = false;
            state.sendEmail.data = null;
            state.sendEmail.error = action.payload;
        },
        sendEmailReset: (state, action) => {
            state.sendEmail = CommunicationInitialData.sendEmail;
        },


        attachmentFileRequestRequesting: (state, action) => {
            state.fileRequest.isLoading = true;
        },
        attachmentFileRequestSuccess: (state, action) => {
            state.fileRequest.isLoading = false;
            state.fileRequest.error = null;
            state.fileRequest.data = action.payload.data;
        },
        attachmentFileRequestError: (state, action) => {
            state.fileRequest.isLoading = false;
            state.fileRequest.error = action.payload;
        },
        attachmentFileRequestReset: (state) => {
            state.fileRequest = CommunicationInitialData.fileRequest;
        },


        emailThreadRequesting: (state, action) => {
            state.thread.isLoading = true;
        },
        emailThreadSuccess: (state, action) => {
            state.thread.isLoading = false;
            state.thread.error = null;
            state.thread.data = action.payload.data;
        },
        emailThreadError: (state, action) => {
            state.thread.isLoading = false;
            state.thread.error = action.payload;
        },
        emailThreadReset: (state) => {
            state.thread = CommunicationInitialData.thread;
        },

        updateEmailRequesting: (state, action) => {
            state.update.isLoading = true;
        },
        updateEmailSuccess: (state, action) => {
            state.update.isLoading = false;
            state.update.error = null;
            state.update.data = action.payload.data;

            const {email, thread} = current(state);

            if (state.email?.data?.results) {
                let temp = [...(email?.data?.results ?? [])];
                state.email.data.results = replaceArrayObjectItem(
                    temp,
                    action.payload.data
                );
            }

            if (state.thread?.data) {
                let temp = [...(thread?.data ?? [])];
                state.thread.data = replaceArrayObjectItem(
                    temp,
                    action.payload.data
                );
            }


        },
        updateEmailError: (state, action) => {
            state.update.isLoading = false;
            state.update.error = action.payload;
        },
        updateEmailReset: (state) => {
            state.update = CommunicationInitialData.update;
        },

        CommunicationMasterReset: () => CommunicationInitialData,
    },
});

export const getEmailAPI = (query) => async (dispatch) => {
    let stringQuery = qargsToQstring(query);
    dispatch(emailCommunicationRequesting());
    let responseURL = endPoints.communication.getEmailConversation;
    apiService
        .get(`${responseURL}` + stringQuery, {})
        .then((response) => {
            if (query.offset === 0) {
                dispatch(emailCommunicationRefreshSuccess(response.data));
            } else {
                dispatch(emailCommunicationSuccess(response.data));
            }
        })
        .catch((error) =>
            dispatch(emailCommunicationError(formatAxiosErrorMessage(error)))
        );
};

export const sendEmailAPI = (data) => async (dispatch) => {
    dispatch(sendEmailRequesting());
    apiService
        .post(endPoints.communication.getEmailConversation, data)
        .then((response) => {
            dispatch(sendEmailSuccess(response.data));
        })
        .catch((error) => {
            dispatch(sendEmailError(formatAxiosErrorMessage(error)));
        });
};

export const emailThreadAPI = (id, query) => async (dispatch) => {
    dispatch(emailThreadRequesting());
    let stringQuery = qargsToQstring(query);
    apiService
        .get(`${endPoints.communication.thread}/${id}/` + stringQuery, {})
        .then((response) => {
            dispatch(emailThreadSuccess(response.data));
        })
        .catch((error) => {
            dispatch(emailThreadError(formatAxiosErrorMessage(error)));
        });
};

export const updateEmailAPI = (id, data) => async (dispatch) => {
    dispatch(updateEmailRequesting());
    apiService
        .patch(`${endPoints.communication.getEmailConversation}${id}/`, data)
        .then((response) => {
            dispatch(updateEmailSuccess(response.data));
        })
        .catch((error) => {
            dispatch(updateEmailError(formatAxiosErrorMessage(error)));
        });
};

export const AttachmentRequestAPI = (id) => async (dispatch) => {
    dispatch(attachmentFileRequestRequesting());
    let responseURL = `${endPoints.communication.download}/${id}/`;


    apiService
        .get(responseURL, {
            responseType: 'blob',
        })
        .then((response) => {
            const blob = new Blob([response.data], {type: response.headers['content-type']});
            const contentDisposition = response.headers['content-disposition'];
            const filenameMatch = contentDisposition && contentDisposition.match(/filename="(.+)"/);
            const filename = filenameMatch ? filenameMatch[1] : 'document_filename.extension';
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', filename); // Set the filename dynamically
            document.body.appendChild(link);
            link.click();
            window.URL.revokeObjectURL(url);
            document.body.removeChild(link);
            dispatch(attachmentFileRequestSuccess("downloaded"));
        })
        .catch((error) =>
            dispatch(attachmentFileRequestError(formatAxiosErrorMessage(error)))
        );
};
export const {
    emailCommunicationRequesting,
    emailCommunicationSuccess,
    emailCommunicationRefreshSuccess,
    emailCommunicationError,
    emailCommunicationReset,

    sendEmailRequesting,
    sendEmailSuccess,
    sendEmailError,
    sendEmailReset,

    attachmentFileRequestRequesting,
    attachmentFileRequestSuccess,
    attachmentFileRequestError,
    attachmentFileRequestReset,

    emailThreadRequesting,
    emailThreadSuccess,
    emailThreadError,
    emailThreadReset,

    updateEmailSuccess,
    updateEmailRequesting,
    updateEmailError,
    updateEmailReset,

    CommunicationMasterReset,
} = CommunicationSlice.actions;
export const CommunicationState = (state) => state.communication;
export default CommunicationSlice.reducer;
