/**
 * Created by WorkAxis www.workaxis.in
 * Developer: Padma Dev.E
 * Developer Contact: padmadev.e@workaxis.in
 * Admin: shyam@workaxis.in
 * Date: 19/08/22
 * Time: 9:22 PM
 * Project: smallcal
 */
import React, {useEffect, useMemo, useRef, useState} from "react";
import "../style.css";
import Refresh from "../../../icon/Refresh";
import {useDispatch, useSelector} from "react-redux";
import {syncNow} from "../../../redux/sync/syncReducer";
import {logoutApi} from "../../../redux/auth/authReducer";
import User from "../../../icon/User";
import LogoutIcon from "../../../icon/LogoutIcon";
import {useNavigate} from "react-router-dom";
import {
    URL_INTEGRATION,
    URL_ORGANIZATION,
    URL_PRICING,
    URL_PROFILE,
    URL_SUBSCRIPTION,
    URL_WHATSAPP_INTEGRATION,
} from "../../../routes/url";
import CreditCardIcon from "../../../icon/CreditCardIcon";
import Cash from "../../../icon/Cash";
import BuildingOffice from "../../../icon/BuildingOffice";
import {profileState} from "../../../redux/profile/profileReducer";
import {AccountSwitcher} from "../../../component/AccountSwitcher/AccountSwitcher";
import {
    TOP_NAV_DROPDOWN_ORG_SUBSCRIPTION,
    TOP_NAV_VIEW_MODE_SWITCHER
} from "../../../component/Check/PrivilegeConstants";
import {PrivilegeCheck} from "../../../component/Check/PrivilegeCheck";
import ChevronDown from "../../../icon/ChevronDown";
import ChevronUp from "../../../icon/ChevronUp";
import WhatsappRawIcon from "../../../icon/WhatsappRawIcon";
import {isCULActionAllowed, isPlanAllowed, isPrivilegeAllowed} from "../../../utils/PrivilegeControl/PrivilegeControl";
import {ANTCRMLOGO, enterprisePlan, ownerRole} from "../../../utils/constants";
import {WhatsappResetState} from "../../../redux/Integration/whatsappReducer";
import IntegrationIcon from "../../../icon/Integration";

export default function TopNavigation({
                                          mobileNavExpand, mobileNavCallback = null,
                                      }) {
    // let brand_logo_pc = "https://ik.imagekit.io/kittydev/Workaxis/Logo/workaxis_dark_5CP_R5uXN.svg";
    // let brand_logo_mobile = "https://ik.imagekit.io/kittydev/Workaxis/Logo/w_dark_TWqhf3EFi.svg";

    const [profileFall, setProfileFall] = useState(false);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {profile, viewingAs} = useSelector(profileState);

    const [allowItems, setAllowItems] = useState(isCULActionAllowed())
    const isEnterpriseAndOwner = isPlanAllowed([enterprisePlan]) && isPrivilegeAllowed([ownerRole])
    useEffect(() => {
        setAllowItems(isCULActionAllowed())
    }, [profile.data, viewingAs.data, dispatch]);


    const brandLogo = useMemo(() => {
        const tempLogo = profile?.data?.userorgrolemapping?.organization?.profile_pic
        if (tempLogo) {
            // return {
            //     mobile: tempLogo,
            //     pc: tempLogo
            // }
            return {
                mobile: ANTCRMLOGO,
                pc: ANTCRMLOGO
            }
        } else {
            return {
                mobile: ANTCRMLOGO,
                pc: ANTCRMLOGO
            }
        }
    }, [profile.data]);

    const Logout = () => {
        dispatch(logoutApi());
    };

    const [syncCSS, setSyncCSS] = useState("w-1-5");

    const sync = () => {
        dispatch(WhatsappResetState())
        dispatch(syncNow(Date.now()));
        setSyncCSS((old) => `${old} rotate`);
        setTimeout(function () {
            setSyncCSS((old) => old.replace("rotate", ""));
        }, 2000);
    };
    const profileFallRef = useRef();

    function handleClick(e) {
        if (profileFallRef.current !== null && !profileFallRef.current.contains(e.target)) {
            setProfileFall(false);
        }
    }

    useEffect(() => {
        if (profileFall) {
            document.addEventListener("click", handleClick);
        } else {
            document.removeEventListener("click", handleClick);
        }
        return () => document.removeEventListener("click", handleClick);
    }, [profileFall]);

    const getProfileDp = () => {
        if (profile.data.profile_pic) {
            return (<img
                alt={profile.data.username}
                className={"top-nav-dp-holder"}
                src={profile.data.profile_pic}
            />);
        } else {
            return (
                <p className={"top-nav-dp-text-holder"}>{profile.data?.username ? profile.data?.username[0] : ""}</p>);
        }
    };

    return (<div className={"top-navigation-parent-container"}>
        <div className={"top-navigation-brand-container"}>
            <div
                className={"onlyMobile p-1 clickable"}
                onClick={() => {
                    if (mobileNavCallback != null) {
                        mobileNavCallback();
                    }
                }}
            >
                {mobileNavExpand ? (<div className={"wx-icons"}>close</div>) : (<div className={"wx-icons"}>menu</div>)}
            </div>
            <img
                src={brandLogo.pc}
                alt="workaxis-logo"
                className={"top-navigation-brand-pc"}
            />
            <img
                src={brandLogo.mobile}
                alt="workaxis-logo"
                className={"top-navigation-brand-mobile"}
            />
        </div>
        <div className="top-navigation-right-controls">
            <div className="top-nav-profile">
                <div className={"d-flex gap-1"}>
                    <div
                        className="refresh-holder"
                        onClick={() => {
                            sync();
                        }}
                    >
                        <Refresh className={syncCSS}/>
                    </div>

                    <PrivilegeCheck allowed={TOP_NAV_VIEW_MODE_SWITCHER}>
                        <div className="shadow-lg d-flex">
                            <AccountSwitcher/>
                        </div>
                    </PrivilegeCheck>

                    <div className="shadow-lg d-flex pr-2 top-nav-profile-container">
                        <div className={"nav-name-card-container cursor-clickable shadow-wx-sm"} onClick={() => {
                            setProfileFall((old) => !old);
                        }}>
                            <div className={"nav-name-dp-holder"}>
                                {getProfileDp()}
                            </div>
                            {profileFall ?
                                <ChevronUp/>
                                : <ChevronDown/>
                            }
                        </div>
                    </div>
                </div>
                {profileFall && (<div className="profile-dropdown shadow-wx-sm" ref={profileFallRef}>
                    <div
                        className="profile-dropdown-item"
                        onClick={() => {
                            navigate(URL_PROFILE);
                            setProfileFall(false);
                        }}
                    >
                        <User/> <p className="ml-05">Profile</p>
                    </div>
                    <div
                        className={`profile-dropdown-item ${allowItems ? '' : 'disabled-calendar-action'}`}
                        onClick={() => {
                            if (allowItems) {
                                navigate(URL_WHATSAPP_INTEGRATION);
                                setProfileFall(false);
                            }
                        }}
                    >
                        <WhatsappRawIcon/> <p className="ml-05">Whatsapp</p>
                    </div>
                    <div
                        className={`profile-dropdown-item ${allowItems ? '' : 'disabled-calendar-action'}`}
                        onClick={() => {
                            if (allowItems) {
                                navigate(URL_INTEGRATION);
                                setProfileFall(false);
                            }
                        }}
                    >
                        <IntegrationIcon/> <p className="ml-05">Integration</p>
                    </div>
                    <PrivilegeCheck allowed={TOP_NAV_DROPDOWN_ORG_SUBSCRIPTION}>
                        {profile.data.userorgrolemapping &&
                            <div
                                className={`profile-dropdown-item ${allowItems ? '' : 'disabled-calendar-action'}`}
                                onClick={() => {
                                    if (allowItems) {
                                        navigate(URL_ORGANIZATION);
                                        setProfileFall(false);
                                    }
                                }}
                            >
                                <BuildingOffice strokeWidth={2.1}/> <p className="ml-05">Organization</p>
                            </div>
                        }
                        <div
                            className={`profile-dropdown-item ${allowItems ? '' : 'disabled-calendar-action'}`}
                            onClick={() => {
                                if (allowItems) {
                                    navigate(URL_SUBSCRIPTION);
                                    setProfileFall(false);
                                }
                            }}
                        >
                            <CreditCardIcon/> <p className="ml-05">Subscription</p>
                        </div>

                    </PrivilegeCheck>


                    <div
                        className={`profile-dropdown-item ${allowItems ? '' : 'disabled-calendar-action'}`}
                        onClick={() => {
                            if (allowItems) {
                                navigate(URL_PRICING);
                                setProfileFall(false);
                            }
                        }}
                    >
                        <Cash/> <p className="ml-05">Pricing</p>
                    </div>
                    <div className="profile-dropdown-item" onClick={() => Logout()}>
                        <LogoutIcon/> <p className="ml-05">Logout</p>
                    </div>
                </div>)}
            </div>
        </div>
    </div>);
}
