import {createSlice} from "@reduxjs/toolkit"
import apiService from "../../api/apiService";
import {formatAxiosErrorMessage} from "../../utils/common";
import {endPoints} from "../../api/endpoints";
import {apiUrlBuilder} from "../../utils/util";


const EmailIntegrationInitialState = {
    configuration: {
        isLoading: false,
        data: null,
        error: null,
    },
    createConfiguration: {
        isLoading: false,
        data: null,
        error: null,
    },
    deleteConfiguration: {
        isLoading: false,
        data: null,
        error: null,
    },
}

export const EmailSlice = createSlice({
    name: "emailintegration",
    initialState: {
        ...EmailIntegrationInitialState
    }, reducers: {
        EmailConfigurationRequesting: (state, action) => {
            state.configuration.isLoading = true
        },
        EmailConfigurationSuccess: (state, action) => {
            state.configuration.isLoading = false
            state.configuration.error = null
            state.configuration.data = action.payload.data
        },
        EmailConfigurationError: (state, action) => {
            state.configuration.isLoading = false
            state.configuration.error = action.payload
        },
        EmailConfigurationReset: (state, action) => {
            state.configuration = EmailIntegrationInitialState.configuration
        },

        EmailCreateConfigurationRequesting: (state, action) => {
            state.createConfiguration.isLoading = true
        },
        EmailCreateConfigurationSuccess: (state, action) => {
            state.createConfiguration.isLoading = false
            state.createConfiguration.error = null
            state.createConfiguration.data = action.payload.data
        },
        EmailCreateConfigurationError: (state, action) => {
            state.createConfiguration.isLoading = false
            state.createConfiguration.error = action.payload
        },
        EmailCreateConfigurationReset: (state, action) => {
            state.createConfiguration = EmailIntegrationInitialState.createConfiguration
        },

        EmailDeleteConfigurationRequesting: (state, action) => {
            state.deleteConfiguration.isLoading = true
        },
        EmailDeleteConfigurationSuccess: (state, action) => {
            state.deleteConfiguration.isLoading = false
            state.deleteConfiguration.error = null
            state.deleteConfiguration.data = "deleted"
        },
        EmailDeleteConfigurationError: (state, action) => {
            state.deleteConfiguration.isLoading = false
            state.deleteConfiguration.data = null
            state.deleteConfiguration.error = action.payload
        },
        EmailDeleteConfigurationReset: (state, action) => {
            state.deleteConfiguration = EmailIntegrationInitialState.deleteConfiguration
        },
    }
})


export const EmailConfigurationAPI = () => async dispatch => {
    dispatch(EmailConfigurationRequesting());
    apiService
        .get(endPoints.integration.email.config, {})
        .then((response) => {
            dispatch(EmailConfigurationSuccess(response.data))
        })
        .catch((error) => dispatch(EmailConfigurationError(formatAxiosErrorMessage(error))));
}

export const EmailCreateConfigurationAPI = (data) => async dispatch => {
    dispatch(EmailCreateConfigurationRequesting());
    apiService
        .post(apiUrlBuilder(endPoints.integration.email.config, null, {}), data)
        .then((response) => {
            dispatch(EmailCreateConfigurationSuccess(response.data))
        })
        .catch((error) => dispatch(EmailCreateConfigurationError(formatAxiosErrorMessage(error))));
}
export const EmailDeleteConfigurationAPI = (id, force = false) => async dispatch => {
    dispatch(EmailDeleteConfigurationRequesting());
    apiService
        .delete(apiUrlBuilder(endPoints.integration.email.config + `${id}/`, null, {force: force}), {})
        .then((response) => {
            dispatch(EmailDeleteConfigurationSuccess(response.data))
        })
        .catch((error) => dispatch(EmailDeleteConfigurationError(formatAxiosErrorMessage(error))));
}

export const {

    EmailConfigurationRequesting,
    EmailConfigurationSuccess,
    EmailConfigurationError,
    EmailConfigurationReset,

    EmailCreateConfigurationRequesting,
    EmailCreateConfigurationSuccess,
    EmailCreateConfigurationError,
    EmailCreateConfigurationReset,

    EmailDeleteConfigurationRequesting,
    EmailDeleteConfigurationSuccess,
    EmailDeleteConfigurationError,
    EmailDeleteConfigurationReset,


    EmailResetState

} = EmailSlice.actions;
export const EmailState = (state) => state.email
export default EmailSlice.reducer